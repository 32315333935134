<template>
  <div id="app">
    <div class="left">
      <p>富强民主</p>
      <p>文明和谐</p>
      <p>自由平等</p>
      <p>公正法治</p>
    </div>
    <div class="right">
      <p>爱国敬业</p>
      <p>诚信友善</p>
      <p>忠党爱国</p>
      <p>遵纪守法</p>
    </div>
    <div v-if="isJumpUrl" style="background-color: black;width: 100%;height: 100%;position: absolute;top: 0;z-index: 9999;display: flex;    justify-content: center;align-items: center;">
      <van-loading type="spinner" color="#1989fa" vertical>选择最佳线路中，请稍等...</van-loading>
    </div>

    <div class="app-inner">
      <van-notice-bar left-icon="volume-o" text="欢迎回家，记住我们的永久域名：madou.cyou" />
      <div class="bg"></div>
      <div class="bg-inner">
        <h1 style="text-align: center;">回家之旅</h1>
        <div class="tag"><span v-for="(i, index) in siteInfo" :key="index" @click="clickInfoItem(i.website_jumpurl)">{{
          i.website_name }}</span></div>
        <van-tabs :background="'#bbb4b4'" :colo="'#fff'" :title-active-color="'red'" :swipeable="true"
          @change="changeTab">
          <van-tab v-for="(i, index) in tabInfo" :title="i.name" :name="i.type" :key="i.name" sticky>
            <div :key="index">
              <SiteItem v-if="httpstatus.length > 0" :httpstatus="httpstatus" :list="list" :isJumpUrl="isJumpUrl"
                @RadioChange="handleFilterData">
              </SiteItem>
              <!-- <van-pagination style="background-color: transparent;" :items-per-page="pageSize" v-model="currentPage"
                :total-items="total" @change="changepagination" /> -->
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>
<script>
import bg from '@/assets/bg.jpg';
import SiteItem from '@/components/siteItem.vue'
export default {
  name: 'HomeView',
  components: {
    SiteItem
  },
  data() {
    return {
      isJumpUrl:false,// 是否跳转
      isDetect:false,//是否被墙
      tabInfo: [
        { name: "AV视频", type: 28 },
        { name: "免费禁漫", type: 35 },
        { name: "笔趣阁", type: 36 },
        { name: "妹子图", type: 37 },
      ],
      siteInfo: [],
      type: 28,
      APIURL: "/api/provide/website/?ac=detail&t=",
      loadStatus: true,
      pageSize: 300,
      pagetotal: 0,
      currentPage: 1,
      total: 0,
      list: [],
      bg: bg,
      httpstatus: [],
      orginList: []
    }
  },
  mounted() {
    this.getConfig()
    this.getData()
  },
  methods: {
    handleFilterData(checked) {
      let fliterList = []
      if (checked == '1') {
        this.getData()
      }
      if (checked == '2') {
        this.list.map((item, index) => {
          if (this.httpstatus[index] == 1) {
            fliterList.push(item)
          }
        })
        this.list = fliterList
      }
    },
    clickInfoItem(url) {
      if (url) {
        window.location.href = url
      }
    },
    changeTab(name) {
      this.type = name
      this.currentPage = 1
      this.total = 0
      this.httpstatus = []
      this.getData()
    },
    changepagination() {
      this.httpstatus = []
      this.getData()
    },
    getConfig() {
      if(this.$route.query['isJumpUrl']&&this.$route.query['isJumpUrl']=='true'){
        this.isJumpUrl = true
      }
      if(this.$route.query['isDetect']&&this.$route.query['isDetect']=='true'){
        this.isDetect = true
      }


      // this.$axios.get(this.APIURL + '38').then((d) => {
      //   if (d.status === 200) {
      //     this.siteInfo = d.data.list
      //   }
      // })
    },
    getData() {
      this.httpstatus = []
      this.$axios.get(this.APIURL + this.type + '&pg=' + this.currentPage).then((d) => {
        if (d.status === 200) {
          this.list = d.data.list
          this.list = this.list.filter((item)=>item['website_remarks']!='被墙')
          this.list.map(() => {
            this.httpstatus.push(3)
          })

          this.total = d.data.total
          this.pagetotal = d.data.pagecount

        }
      })
    }
  }
}
</script>

<style>


.van-pagination__item {

  background-color: transparent !important;
}

* {
  margin: 0 0;
  padding: 0 0;

}

body {
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAgMAAABjUWAiAAAADFBMVEXr5djn4dTp49bt59rT6LKxAAACnElEQVQozw3NUUwScRzA8d8R6MF8YMIx8uk47hDSJbj14IPzOGc7jPLvwTGg5uAYDbe2tt56cLtznvEnS6yDqCcEaWi91DvrbLJZz7b1aFtz1aO+2OZWvn+/+4CHeB6BMYaqBLfjPNRY6RFT2JJYby+uAk4WUTrtlmJ4hgPYb2q1XGDQjaK8pgJHvqNaAX+KyuIkDXpgQinb46nOulnn4b5laUHTxLfseeArAoNOeJlOIjdoal0n1FA7tKFv5roK+YaHOqP3P0XyKHPHY+MhTRe5uCZnKhtJKw2eSrSoBDPLtpZuNcFNJcFyiCMxOaaHIfXz1e8HQbWLySrBQ4x0x1qlhnHlnz2HQEC6TNb0gTHXa7IKhcaHqkE015hk9whA0YeWiLIXf7Fa2CZo3DjqjB4tTuF8jIcbfcEx5z/w4sXpQhXW+ju0cqh7icTFmRMaG+v6CIvTjcSpHcH8JEsF3EPh3fRthYdVLLgI2fWXm85/pGFE4l046s70L+yKCcirGFR+jbpy3kMmiCGHrSezVONsn1RBixncyk2PcVWk7DlgxHo8iZwDyq5uAUD854dZhdIFYzKoQig2haUKi1lVufz2RZUZPZ41n/hrOQB6h0Hhg8I367FNoEHgeM/KY7szSeQwD8q2WE3HM35ZLl0K1MJiOtHIkBclRQUwZnyOWcNsRQQgVLj1PSqkjF9DsoOSaSg3iinKzvfmgsNFFfpP/2T3GLGvL4fHEfwIX1sVvXcPqLztehWGcfn9nI2U9nTfCgJPe/jFPLZwgVEzimBgAm0VIyK2tt1cE/AzQdLK+SxLSQ4aDCZnnId94OG2S1XwvnTbNk/ZnhyRCQT+sZM6z9g6LXL1BOBe+zJySiFkHAINCtnQokbCJ/apCv0foqPiZVfhpywAAAAASUVORK5CYII=);
}

#app {
  padding: 0 0;
  width: 100%;
}

.app-inner {
  width: 100%;
  color: #2c3e50;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-size: 13px;
  min-height: 100vh;
  position: relative;

}

.tag {
  text-align: end;
  margin-top: 20px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.tag span {
  cursor: pointer;
  margin-left: 15px;
  color: #000;
  font-size: 14px
}

.left,
.right {
  position: absolute;
  width: calc(50% - 400px);
  font-size: 28px;
  color: red;
  font-weight: 600;
  height: 100%;
  display: flex;
  flex-flow: column-reverse;
  justify-content: center;
  align-items: center;

}

.left p,
.right p {
  letter-spacing: 5px !important;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.bg-inner {
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100vh;
  color: #000;
  padding: 10px 10px;
  box-sizing: border-box;
}

.bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 800px;
  width: 100%;
  min-height: 100vh;
  background-size: contain;
  filter: blur(10px);
}





@media screen and (max-width:786px) {

  .left,
  .right {
    display: none
  }
}
</style>






