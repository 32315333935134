import Vue from 'vue'
import axios from "axios";
import App from './App.vue'
import router from './router'
import { Image, Tab, Tabs, Pagination, Loading, NoticeBar, RadioGroup, Radio } from 'vant';
import 'vant/lib/index.css'

Vue.use(Image)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(NoticeBar)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
