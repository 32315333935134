<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0 0;
  padding: 0 0;
}

#app {
  width: 100%;
  height:100%;
  color: #2c3e50;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
