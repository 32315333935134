<template>
    <div>
        <div style=" display: flex;justify-content: center;align-items: center;margin-top:10px;">
            <span style="margin-right: 10px;">筛选</span>
            <van-radio-group v-model="checked" direction="horizontal" style="" @change="changeRadio">
                <van-radio name="1">全部</van-radio>
                <van-radio name="2">未被墙</van-radio>
            </van-radio-group>
        </div>
        <div class="sitemap" v-for="(item, index, key) in list" :key="key">
            <div style="width: 30px;flex:0;text-align: center;">
                {{ index + 1 }}
            </div>
            <div style="text-align: center;">
                <span>{{ item.website_name }}</span>
            </div>
            <div style="text-align: center;font-size: 12px;flex:2">
                <span @click="clickBtn(item.website_jumpurl)"> {{ item.website_jumpurl }}</span>
            </div>
            <div class="status">
                <span>状态：</span>
                <span :style="{ color: httpstatus[index] == 1 ? '#26a145' : 'red' }">
                    {{ httpstatus[index] === 3 ? '检测中...' : httpstatus[index] == 1 ? '正常' : '被墙' }}
                </span>
            </div>
            <div class="btn-warp" style="flex: 0;">
                <span class="btn" :style="{ color: httpstatus[index] == 1 ? '#26a145' : 'red' }"
                    @click="clickBtn(item.website_jumpurl)"> {{ httpstatus[index] === 3 ? '检测中...' :
                        httpstatus[index] == 1 ?
                            '点击进入' : '被墙' }}</span>
            </div>
            <van-image style="display: none;" width="0" height="0" :src="item.website_jumpurl + '/logo.png'"
                @load="imgload(index)" @error="imgerror(index)" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'SiteItem',
    data() {
        return {
            checked: "1",
            dataList: [],
            activeIndex: 0,

        }
    },
    props: {
        isJumpUrl: Boolean,
        msg: String,
        tabType: Number,
        list: {
            type: Array,
            default: () => []
        },
        httpstatus: {
            type: Array,
            default: () => []
        }
    },

    mounted() {
        this.checked = '1'
    },
    methods: {
        changeRadio() {
            this.$emit('RadioChange', this.checked)
        },
        imgload(index) {
            this.$set(this.httpstatus, index, 1)
            this.activeIndex = index
            if (this.isJumpUrl) {
                window.location.href = this.list[index]['website_jumpurl']
            }

        },
        imgerror(index) {
            this.$set(this.httpstatus, index, 0)
        },
        clickBtn(url) {
            if (url)
                window.location.href = url
        },
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sitemap {
    display: flex;
    justify-content: center;
    align-items: center
}

.sitemap div {
    flex: 1;
    height: 50px;
    line-height: 50px;
}

.status {
    min-width: 90px;
    text-align: center;
}

.btn-warp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    height: 30px;
    line-height: 30px;
    display: block;
    width: 55px;
    border-radius: 5px;
    background-color: #fff3f3;
    text-align: center;
    cursor: pointer;
    color: rgb(64, 223, 40);
    font-size: 12px
}
</style>